import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";
import SiteLink from "../../components/SiteLink";

const title = "Irritable Bowel Syndrome (IBS)";

// Download link - http://www.fbrennangastro.com.au/downloads/gesairritablebowelsyndrome-3rded.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "IBD", "Crohn's", "Ulcerative colitis"]}
			type="article"
			description="Irritable bowel syndrome (IBS) -  what it is, differences with IBD and Crohn's, symptoms and how to control it"
		/>
		<H1>{title}</H1>
		<Par>
			Irritable bowel syndrome (IBS) is a common problem affecting the colon or large bowel. Around one in five
			Australians experiences the unpleasant symptoms of IBS at some time. IBS does not damage the bowel or lead to
			other health problems.
		</Par>
		<Par>
			Irritable bowel syndrome (IBS) refers to a disorder that involves abdominal pain and bloating, as well as changes
			in bowel movements. Some people suffer constipation, some have diarrhoea and some alternate between the two.
		</Par>
		<Par>
			IBS is not the same as{" "}
			<SiteLink to="/articles/inflammatory-bowel-disease-IBD-crohns-and-colitis">
				inflammatory bowel disease (IBD), which includes Crohn's disease and ulcerative colitis.
			</SiteLink>
		</Par>
		<Par>Most people can control their symptoms with diet, stress management and prescribed medications.</Par>
	</Blog>
);
